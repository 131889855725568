<template>
  <div ref="parent">
    <a-modal
      centered
      :width="types === 'import' ? 560 : 454"
      :title="modalTitle"
      :visible="visible"
      @cancel="handleCancel"
      @ok="comfirmAdd"
      :getContainer="() => $refs.parent"
      :closable="uploadState != 1"
      :footer="uploadState != 1 ? undefined : null"
      :maskClosable="false"
      :keyboard="false"
    >
      <section v-if="types === 'pass'">
        <a-form ref="formRef" :rules="rules" :model="modalVal">
          <a-form-item label="新密码" name="pass" :labelCol="{ span: 5 }" style="margin-bottom:0">
            <a-input-password
              autoComplete="new-password"
              placeholder="请输入新密码"
              v-model:value="modalVal.pass"
              class="form-item-width"
              @change="modalVal.pass = modalVal.pass.replace(/[\u4e00-\u9fa5/\s+/]/gi, '')"
              :maxlength="16"
            />
            <p class="tips form-item-width top-tips">重置密码后请告知人员，以确保其正常登录</p>
            <p class="tips form-item-width">密码必须是8-16位的英文字母、数字、字符组成，且至少含3种以上字符</p>
          </a-form-item>
        </a-form>
      </section>
      <section v-else>
        <div v-if="uploadState == 0">
          <a-form-item label="下载模板" :labelCol="{ span: 3 }" style="margin-bottom:8px">
            <a @click="downLoadExample" style="color:#1890FF">点此下载批量导入Excel模板文件</a>
          </a-form-item>
          <!-- <a-form-item label="上传附件" :labelCol="{ span: 5 }"> -->
          <DraggerUpload
            v-model:fileList="state.fileList"
            :callback="getFile"
            :action="state.action"
            :fileType="state.fileType"
            :tips="false"
            ref="uploadRef"
          />
          <!-- </a-form-item> -->
          <!-- <p class="tips mb-10" style="">批量导入说明：</p> -->
          <p class="tips">1.请下载模板，使用Excel等软件按模板的格式进行填写。不得删除、修改excel列顺序</p>
          <p class="tips">
            2.新账号无密码，人员可使用手机验证码登录后自行设置密码。如果该用户已存在其他企业，使用原密码。
          </p>
        </div>
        <div v-else>
          <div class="upload-wrap">
            <div class="state-wrap">
              <div class="upload-ing" v-if="uploadState == 1">
                <a-spin :indicator="indicator" />
                <p class="title">导入中...</p>
              </div>
              <div class="upload-success" v-if="uploadState == 2">
                <div class="icon">
                  <img :src="successImg" alt="" />
                </div>
                <p class="title">导入成功</p>
                <p class="tips">导入成功，共导入{{ uploadCallbackData.dataSize }}条数据</p>
              </div>
              <div class="upload-fail" v-if="uploadState == 3">
                <div class="icon">
                  <img :src="failImg" alt="" />
                </div>
                <p class="title">导入失败</p>
                <p class="tips" v-if="uploadCallbackData.errMsg">{{ uploadCallbackData.errMsg }}</p>
              </div>
            </div>
            <a-progress
              v-if="uploadState == 1"
              :percent="per"
              size="small"
              status="active"
              :showInfo="false"
              strokeLinecap="square"
              strokeColor="#07C160"
              trailColor="#DDDDDD"
            />
            <div class="file-wrap">
              <img class="exl" :src="excelImg" alt="" />
              <p>{{ fileRef.name }}</p>
            </div>
          </div>
          <p class="table-t" v-if="errDataMsg.length != 0 && uploadState == 3">错误原因</p>
          <a-table
            class="reason-list"
            size="small"
            v-if="errDataMsg.length != 0 && uploadState == 3"
            :columns="columns"
            :data-source="errDataMsg"
            :pagination="false"
            :scroll="{ y: 145 }"
            :rowClassName="(record, index) => 'table-sm'"
            bordered
          ></a-table>
        </div>
      </section>
      <template #footer>
        <a-button @click="handleCancel" class="MyCancel" v-if="uploadState == 0">取消</a-button>
        <a-button class="comfirm" :loading="loading" @click="comfirmAdd" v-if="uploadState == 0">
          确定
        </a-button>
        <a-button class="comfirm" @click="handleCancel" v-if="uploadState == 2 || uploadState == 3">
          确定
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed, watch, h } from 'vue'
import DraggerUpload from '@/components/Upload/draggerUpload'
import { cmsNotice } from '@/utils/utils'
import { Empty } from 'ant-design-vue'
import { LoadingOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    DraggerUpload
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    types: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    reRender: {
      type: Object,
      default: () => {}
    },
    upState: {
      type: Number,
      default: 0
    },
    uploadCallbackData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const uploadRef = ref()
    const titleObj = {
      pass: '重置密码',
      import: '批量导入'
    }
    const state = reactive({
      fileList: [],
      fileType: ['xls']
    })
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '72px',
        color: '#B1B1B1'
      },
      spin: true
    })
    const columns = [
      {
        title: '表格位置',
        dataIndex: 'position',
        key: 'position'
      },
      {
        title: '错误原因',
        dataIndex: 'reason',
        key: 'reason'
      }
    ]
    let errDataMsg = ref([])
    let fileRef = ref()
    const uploadStateEnum = reactive({
      pre: 0,
      ing: 1,
      success: 2,
      fail: 3
    })
    let timer
    let per = ref(0)
    let uploadState = ref(0)
    const excelImg = require('@/assets/images/excel.png')
    const successImg = require('@/assets/images/success.png')
    const failImg = require('@/assets/images/fail.png')
    const modalTitle = computed(() => titleObj[props.types])
    const formRef = ref()
    const closable = ref(true)
    const modalVal = reactive({
      pass: undefined,
      userId: undefined
    })
    const rules = ref()
    rules.value = {
      pass: [
        {
          required: true,
          message: '请输入新密码'
        },
        { min: 8, max: 16, message: '请输入8-16位密码', trigger: 'blur' }
      ]
    }
    const downLoadExample = () => {
      // 下载导入案例
      cmsNotice('success', '正在为您下载，请耐心等待...')
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/excel/人员导入模板.xls`
    }
    const getFile = file => {
      console.log('eeee', file)
      // 导入人员的文件
      fileRef.value = file
    }
    const comfirmAdd = () => {
      // 弹框确定
      emit('update:loading', true)
      if (props.types === 'pass') {
        formRef.value
          .validate()
          .then(() => {
            emit('modalSubmit', modalVal)
          })
          .catch(() => emit('update:loading', false))
      } else if (props.types === 'import') {
        emit('update:loading', false)
        const isSuccess = uploadRef.value.validateFile()
        if (!isSuccess) {
          // emit('update:loading', false)
          return
        }
        // uploadState.value = 3
        // return
        emit('modalSubmit', fileRef.value)
      }
    }

    const handleCancel = () => {
      // 关闭弹框
      props.types === 'pass' && formRef.value.resetFields()
      errDataMsg.value = []
      uploadState.value = 0
      emit('update:visible', false)
      emit('update:upState', 0)
    }

    watch(
      () => props.visible,
      newValue => {
        if (!newValue) return
        const typeObjFn = {
          pass() {
            modalVal.pass = undefined
            modalVal.userId = props.reRender.userId
          },
          import() {
            state.fileList = []
          }
        }
        typeObjFn[props.types] && typeObjFn[props.types]()
      }
    )
    watch(
      () => props.uploadCallbackData,
      newValue => {
        errDataMsg.value = []
        if (newValue.errDataMsg) {
          let i = 1
          for (var p in newValue.errDataMsg) {
            errDataMsg.value.push({ key: i, position: p, reason: newValue.errDataMsg[p] })
            i++
          }
          console.log('errDataMsg', errDataMsg)
        }
      }
    )
    watch(
      () => props.upState,
      newValue => {
        console.log('newValue', newValue)
        if (newValue == 0) {
          uploadState.value = newValue
          per.value = 0
        } else if (newValue == 1) {
          uploadState.value = newValue
          timer = setInterval(() => {
            if (per.value < 100) {
              per.value += 10
            } else {
              clearInterval(timer)
            }
          }, 100)
        } else {
          per.value = 100
          clearInterval(timer)
          setTimeout(() => {
            uploadState.value = newValue
          }, 500)
        }
        console.log()
      }
    )

    return {
      comfirmAdd,
      formRef,
      closable,
      modalVal,
      rules,
      modalTitle,
      uploadRef,
      state,
      downLoadExample,
      handleCancel,
      getFile,
      uploadStateEnum,
      uploadState,
      excelImg,
      successImg,
      failImg,
      fileRef,
      per,
      indicator,
      columns,
      errDataMsg,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  }
})
</script>

<style lang="scss" scoped>
.mb-10 {
  margin-bottom: 10px;
}
.search-btn {
  margin-left: 16px;
  border-radius: 2px;
}
:deep(.ant-checkbox-wrapper) {
  display: block;
  margin-left: 0;
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  margin-bottom: 12px;
  height: 22px;
  line-height: 22px;
}
.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;
  &:hover {
    border-color: transparent;
  }
}
.tips {
  width: 512px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: justify;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}
:deep(.ant-select) {
  .ant-select-selector {
    border-radius: 4px;
    line-height: 36px;
  }
}
.check-box {
  padding: 16px;
  background: #f7f8fa;
  max-height: 343px;
  overflow: hidden;
  overflow-y: scroll;
}
.form-item {
  margin-bottom: 16px;
}
.form-item-width {
  width: 320px;
}
.top-tips {
  font-weight: 400;
  color: #999999;
}
// :deep(.ant-empty-normal){
//   margin: 120px 0;
// }
:deep(.ant-upload) {
  width: auto;
  height: auto;
}
.upload-wrap {
  width: 512px;
  border: 1px dashed #dddddd;
}
.state-wrap {
  width: 100%;
  padding: 32px 0 50px;
  background-color: #f9f9f9;
  text-align: center;
  .icon {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    margin: auto;
    img {
      width: 56px;
      height: 56px;
    }
  }
  p {
    margin-top: 10px;
  }
  .title {
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-top: 16px;
  }
  .upload-success {
    // img {
    //   box-shadow: 0px 4px 10px 0px rgba(7, 193, 96, 0.2);
    // }
    .icon {
      box-shadow: 0px 4px 10px 0px rgba(7, 193, 96, 0.2);
    }
    .title {
      font-weight: 600;
    }
    .tips {
      height: 20px;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      text-align: center;
    }
  }
  .upload-fail {
    // img {
    //   box-shadow: 0px 4px 10px 0px rgba(7, 193, 96, 0.2);
    // }
    .icon {
      box-shadow: 0px 4px 10px 0px rgba(195, 22, 28, 0.2);
    }
    .title {
      font-weight: 600;
    }
    .tips {
      height: 20px;
      font-size: 14px;
      color: #c3161c;
      line-height: 20px;
      text-align: center;
    }
  }
}
.file-wrap {
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  .exl {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
  p {
    height: 20px;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
  }
}
.table-t {
  height: 20px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  text-align: left;
  margin: 16px 0 8px;
  font-weight: 600;
}
// :deep(.ant-table-tbody > tr > td) {
//   height: 20px;
//   line-height: 20px;
// }
:deep(.ant-table-tbody > tr > td) {
  height: 24px !important;
  line-height: 24px !important;
}
:deep(.ant-table-column-title) {
  color: rgba(0, 0, 0, 0.45);
}
:deep(.ant-table-row td:last-child) {
  color: #c3161c;
}
:deep(.ant-progress) {
  display: block;
}
:deep(.ant-progress-outer) {
  display: block;
}
:deep(.ant-progress-inner) {
  display: block;
}
</style>
